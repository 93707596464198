import React from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RiArrowDropDownLine } from "react-icons/ri";
import { API_URL } from "../utils/consts";
import { Store, useStore } from "../store";
import { Select } from "@headlessui/react";
import { countriesNumData } from "../utils/geo";

const Country = () => {
  const { t } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const { country } = userData;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value
    setUserData({ ...userData, country: selectedCountry })

    axios.patch(`${API_URL}/users/${userData.id}/country`, { country: selectedCountry })
      .catch((err) => console.log(err))
  };

  return(
    <>
      <label htmlFor="country-select" className="block mb-2">
        {t('country.label')}
      </label>

      <div className="grid">
        <Select
          id="country-select"
          name="country"
          value={country || ""}
          onChange={handleChange}
          className="appearance-none row-start-1 col-start-1 px-4 block w-[250px] !border-none text-white text-center rounded-full bg-[rgba(0 ,0 ,0, 0.15)] forced-colors:appearance-auto"
          style={{ backgroundColor: 'rgba(0 ,0 ,0, 0.15)' }}
          required
        >
          {!country && <option value="" disabled hidden>{t('country.placeholder')}</option>}
          {countriesNumData.sort((a, b) => a.name.localeCompare(b.name)).map(country =>
            <option key={country.code} value={country.code}>{t(`country.${country.code}`)}</option>
          )}
        </Select>

        <RiArrowDropDownLine size={24} className="pointer-events-none z-10 right-1 relative col-start-1 row-start-1 self-center justify-self-end forced-colors:hidden"/>
      </div>
    </>
  )
}

export default Country;