import React, { Fragment, useMemo } from "react";
import { get } from "lodash";
import { Feature } from "geojson";
import * as d3 from 'd3';
import { geoData } from "../utils/geo";
import { Store, useStore } from "../store";

const BUBBLE_MIN_SIZE = 5;
const BUBBLE_MAX_SIZE = 25;

const OPACITY_MIN_SIZE = 0.2;
const OPACITY_MAX_SIZE = 0.8;

type MapProps = {
  width: number;
  height: number;
  userCountryCode?: string;
  isJoining: boolean;
};

const Map = ({ width, height, userCountryCode, isJoining }: MapProps) => {
  const appData = useStore((state: Store) => state.appData)
  const stringifiedActiveUsersPerCountry = JSON.stringify(appData.activeUsersPerCountry);

  const sizeScale = useMemo(() => {
      const numData = (JSON.parse(stringifiedActiveUsersPerCountry) || []) as { country: string, activeUsers: number}[];
      const [min, max] = d3.extent(numData.filter(d => d.activeUsers > 0).map((d) => d.activeUsers));
      return d3.scaleSqrt().domain([1, max || 1]).range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE]);
    },
    [stringifiedActiveUsersPerCountry]
  );

  // const countryOpacity = useMemo(
  //   () => {
  //     const newNumData = JSON.parse(JSON.stringify(countriesNumData)) as MapNumDataType[];
  //     JSON.parse(stringifiedActiveUsersPerCountry).forEach((countryActiveUsers: { country: string, activeUsers: number}) => {
  //       const element = newNumData.find((d) => d.name === countryActiveUsers.country)
  //       if (element) {
  //         element.value = Number(countryActiveUsers.activeUsers)
  //       }
  //     })
  //
  //     const [min, max] = d3.extent(newNumData.map((d) => d.value)) as [number, number];
  //
  //     const opacityScale = d3
  //       .scaleSqrt()
  //       .domain([min, max])
  //       .range([OPACITY_MIN_SIZE, OPACITY_MAX_SIZE]);
  //
  //     const newCountryOpacity = {} as { [key: string]: number };
  //     newNumData.forEach(region => {
  //       const regionGeoData = geoData.features.find(
  //         (geoRegion: any) => geoRegion.id === region.code
  //       );
  //       if (regionGeoData && regionGeoData.id && region.value > 0) {
  //         newCountryOpacity[String(regionGeoData.id)] = opacityScale(region.value);
  //       }
  //     })
  //
  //     return newCountryOpacity
  //   },
  //   [stringifiedActiveUsersPerCountry]
  // )

  const projection = useMemo(() => d3
    .geoNaturalEarth1()
    .scale(width / 2.3 / Math.PI)
    .center([0, 45])
    .translate([width/2, height/2]),
  [width, height]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const userLocationCoordinates = useMemo<[number, number] | null>(() => {
    const userRegionGeoData = geoData.features.find((geoRegion) =>
      get(geoRegion, 'id') === userCountryCode
    );

    return get(userRegionGeoData, 'properties.center');
  }, [userCountryCode])

  const getCurvedPath = (p1: number[], p2: number[]) => {
    const [x1, y1] = p1;
    const [x2, y2] = p2;
    let cx = (x1 + x2) / 2;
    let cy = Math.min(y1, y2) - Math.abs(x2 - x1) * 0.4;

    return `M ${x1},${y1} Q ${cx},${cy} ${x2},${y2}`;
  }

  const allCountryPaths = geoData.features
    .filter((shape: Feature) => shape.id !== 'ATA')
    .map((shape: Feature) => {
      const activeUsersInCountry = JSON.parse(stringifiedActiveUsersPerCountry).find((countryActiveUsers: { country: string, activeUsers: number }) => {
        return get(shape, 'id') === countryActiveUsers.country
      })?.activeUsers || 0 //TODO optimize
      const centroid = projection(get(shape, 'properties.center'));
      const userCentroid = userLocationCoordinates ? projection(userLocationCoordinates) : null;
      const isUserCountry = get(shape, 'id') === userCountryCode;
      const circleRadius = sizeScale(activeUsersInCountry);

      return (
        <Fragment key={shape.id}>
          <path
            key={`shape-${shape.id}`}
            className="transition-[fill-opacity] duration-1000 delay-500"
            d={geoPathGenerator(shape) || undefined}
            fill="#ffffff"
            // fillOpacity={countryOpacity[String(shape.id)] || 0.1}
            fillOpacity={0.1}
            strokeWidth={0}
            stroke="#ffffff"
          />
          {centroid && (
            <>
              <circle
                key={`circle-${shape.id}`}
                r={activeUsersInCountry > 0 ? circleRadius : (isUserCountry ? 3 : 0)}
                cx={centroid[0]}
                cy={centroid[1]}
                fillOpacity={activeUsersInCountry > 0 || isUserCountry ? 0.2 : 0}
                fill="#ffffff"
                filter="drop-shadow(0px 0px 4px #fff)"
                className="transition-[r] duration-[2000ms]"
              />

              {userCentroid && activeUsersInCountry > 0 && !isUserCountry && (
                <path
                  key={`curve-${shape.id}`}
                  className="transition-[stroke-opacity] duration-[1000ms]"
                  stroke="#ffffff"
                  strokeWidth="1"
                  strokeOpacity={isJoining ? 0.3 : 0.0}
                  fill="none"
                  d={getCurvedPath(centroid, userCentroid)}
                />
              )}
            </>
          )}
        </Fragment>
      );
    });
    
  return (
    <svg width={width} height={height}>
      <defs>
        <radialGradient id="myGradient">
          <stop offset="30%" stopColor="white" />
          <stop offset="100%" stopColor="white" stopOpacity={0} />
        </radialGradient>
      </defs>
      {allCountryPaths}
    </svg>
  );
};

export default Map;