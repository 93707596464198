import React, {useEffect, useState} from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next'
import { motion, useMotionValue } from 'framer-motion';
import { API_URL } from "../../utils/consts";
import { Store, useStore } from "../../store";
import Map from "../../stats/Map";
import Settings from "../../components/Settings";
import Stats from "../../stats";

const poolingInterval = 10000;

const Home = () => {
  const demo = window.location.search.includes("demo=true")

  const { t } = useTranslation();

  const goToPage = useStore((state: Store) => state.goToPage)
  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const fetchAppData = useStore((state: Store) => state.fetchAppData)

  const [mapSize, setMapSize] = useState({ width: 0, height: 0 })
  const [isJoining, setIsJoining] = useState(false);

  useEffect(() => {
    if (userData.id !== "null") {
      fetchAppData();
      const intervalId = setInterval(fetchAppData, poolingInterval);

      return () => clearInterval(intervalId);
    }
  }, [userData.id, fetchAppData])

  useEffect(() => {
    const updateMapWidth = () => {
      let mapWidth = Math.min(window.outerWidth, 700);
      const mapHeight = Math.min(window.outerHeight / 2, mapWidth * 9 / 14);
      if (mapWidth > mapHeight * 16 /9) {
        mapWidth = mapHeight * 16 /9
      }
      setMapSize({ width: mapWidth - 24, height: mapHeight - 24 });
    }
    window.addEventListener('resize', updateMapWidth);
    updateMapWidth();

    return () => window.removeEventListener('resize', updateMapWidth);
  }, []);

  const join = () => {
    setIsJoining(true);
    if (userData.id === "null") {
      setUserData({ ...userData, userIntentions: [
        {
          id: 0,
          intentionId: 0,
          userId: "null",
          startTime: new Date(),
          endTime: undefined,
          autoFinished: false,
        }
      ] })

      const newCountryStats = [...appData.activeUsersPerCountry]
      if (userData.country) {
        const userCountryStat = newCountryStats.filter(cs => cs.country === userData.country)[0]
        if (userCountryStat) {
          userCountryStat.activeUsers = userCountryStat.activeUsers + 1
        } else {
          newCountryStats.push({ country: userData.country, activeUsers: 1 })
        }
      }


      setAppData({ ...appData, activeUsers: appData.activeUsers + 1, activeUsersPerCountry: newCountryStats })
      setTimeout(() => goToPage("meditation"), 1500);
      return;
    }

    axios.post(`${API_URL}/user_intentions/start`, { user_id: userData.id, intention_id: appData.intention.id })
      .then(response => {
        setAppData({...appData, activeUsers: appData.activeUsers + 1})
        setUserData({ ...userData, userIntentions: [...userData.userIntentions, response.data] })
        fetchAppData();
        setTimeout(() => goToPage("meditation"), 1500);
      })
      .catch(err => console.log(err));
  }

  const overflow = useMotionValue("auto");
  const onAnimationStart = () => { overflow.set("hidden") };
  const onAnimationComplete = () => { overflow.set("auto") };

  return(
    <motion.div
      className="page h-full"
      // style={{ overflow: overflow.get() }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0,
        transition:{
          duration: 0.5,
          delay: 0.6
        }
      }}
    >
      <div className="w-full flex-1 flex flex-col justify-center items-center">
        <Settings />

        {appData.intention && (
          <>
            <motion.p
              key="header"
              className="text-sm uppercase secondary"
              initial={{ opacity: 0, y: -60 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -60, transition: { duration: 0.5, delay: 0.3, ease: "easeIn" } }}
              transition={{
                duration: 0.5,
                delay: 0.3,
                ease: "easeIn"
              }}
            >
              {t('home.intentionHeader')}
            </motion.p>

            <motion.p
              key="intention"
              className="my-12 light text-2xl"
              style={{ textShadow: "0px 0px 10px white"}}
              initial={{ opacity: 0, y: -60 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -60, transition: { duration: 0.5, delay: 0.3, ease: "easeIn" } }}
              transition={{
                duration: 0.5,
                delay: 0.5
              }}
            >
              {appData.intention.text}
            </motion.p>

            <motion.button
              key="button"
              onClick={join}
              className="btn primary"
              disabled={isJoining}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.8, delay: 0, ease: "easeIn" } }}
              transition={{
                duration: 0.5,
                delay: 0.7
              }}
            >
              {t('home.buttonLabel')}
            </motion.button>
          </>
        )}
      </div>

      {(userData.id !== "null" || demo) && (
        <motion.div
          className="w-full mt-8 flex flex-col justify-center items-center"
          initial={{ opacity: 0, y: 60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 60, transition: { duration: 0.5, delay: 0.3, ease: "easeIn" } }}
          transition={{
            duration: 0.5,
            delay: 0.9
          }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <Map
            userCountryCode={userData.country}
            width={mapSize.width}
            height={mapSize.height}
            isJoining={isJoining}
          />
          <Stats appData={appData} />
        </motion.div>
      )}
    </motion.div>
  );
}

export default Home;
